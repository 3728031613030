import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const container = {
    hidden: {},
    visible: {
        transition: { 
            staggerChildren: 0.2 
        }, 
    },
};

const projectVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
};

const Project = ({ title, image, description, web }) => {
    const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500 
    bg-grey z-30 flex flex-col justify-center items-center text-center p-16 text-deep-blue`;
    const projectTitle = title.split(" ").join("-").toLowerCase();

    return(
        <motion.div variants={projectVariant} className="relative">
            <div className={overlayStyles}>
                <p className="text-2xl font-playfair">{title}</p>
                <p className="mt-7">
                {description}
                </p>
                {/* 👇️ open link in new tab */}
            <a href={web} target="_blank" rel="noopener noreferrer" className="text-red font-semibold mt-5">
            Visit
            </a>
            </div>
            <img src={`../assets/${image}`} alt={projectTitle} />
        </motion.div>
    );
};

const Projects = () => {
    return(
        <section id="projects" className="pt-48 pb-48">
            {/* HEADINGS */}
            <motion.div
            className="md:w-2/5 mx-auto text-center"
            initial="hidden"
            whileInView="visible"                
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: -50 },
                visible: { opacity: 1, y: 0 }
            }}
            >
                <div>
                    <p className="font-playfair font-semibold text-4xl">
                        <span className="text-red">PRO</span>JECTS
                    </p>
                    <div className="flex justify-center mt-5">
                        <LineGradient width="w-2/3" />
                    </div>
                </div>
                <p className="mt-10 mb-10">
                After operating in the software development business for more than 5+ years, MAXOBYTE has collected a large number of valuable testimonials. 
                Hundreds of completed projects and satisfied clients provided us with a great feedback on our services. 
                We are proud of our rich portfolio. Please click the links below to see samples of our work in each category.
                </p>
            </motion.div>

            {/* PROJECTS */}
            <div className="flex justify-center">
                <motion.div 
                className="sm:grid sm:grid-cols-3"
                variants={container}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                >
                    {/* ROW 1 */}
                    <div
                    className="flex justify-center text-center items-center p-10 bg-red max-w-[400px] max-h-[400px] 
                    text-2xl font-playfair font-semibold"
                    >
                        BEAUTIFUL USER INTERFACES
                    </div>
                    <Project 
                    title="Froomie" 
                    image="froomie.jpg" 
                    description="Froomie is the all-in-one college housing app: find roommates, rentals, and services for your university!"
                    web="https://apps.apple.com/us/app/froomie/id1570687219?platform=iphone"/>
                    <Project 
                    title="Hot Investments" 
                    image="hot.jpg" 
                    description="HotInvestments is designed for investors and agents to list and purchase off-market properties.
                    Investors get first dibs at incoming listings, have direct contact with the seller or listing agent.
                    Agents have the opportunity to double-end by marketing their listings on a different and unique
                    platform."
                    web="https://apps.apple.com/us/app/hot-investments/id1548755568?platform=iphone"/>

                    {/* ROW 2 */}
                    <Project title="GAAN: Bangla Music Streaming"
                    image="gaan.jpg" 
                    description="GAAN is a music streaming app for Bangladeshis all across the globe. 
                    Founded in 2015, the app was the first independent music streaming service provider for 
                    Bangla Music and Bangladeshi Musicians."
                    web="https://play.google.com/store/apps/details?id=bd.com.gaan.shur22&hl=en&gl=US"/>
                    <Project title="Saddle Genie"
                    image="saddle.png" 
                    description="Find the saddle of your dreams with the Saddle Genie Virtual Fitting App!"
                    web="https://apps.apple.com/us/app/saddle-genie/id1597061848?platform=iphone"/>
                    <Project title="SportyO - Live Sports Game"
                    image="sportyo.png" 
                    description="SportyO is a Virtual Sports betting game that has the benefits of sports betting without actually losing any money. 
                    There is no money or risk involve in this. Many individuals shy away from betting because of the risks involved.  
                    This is absolutely free to use and always will be."
                    web="https://play.google.com/store/apps/details?id=com.shohanrahman.sportyo"/>

                    {/* ROW 3 */}
                    <Project title="KorbytGo"
                    image="korbyt.jpg" 
                    description="Your workforce is on the move — so get your workplace on-the-go.
                    Whether your team is on site or working remote, connect people and technology through mobile. With Korbyt Anywhere’s mobile app you can create and deliver personalized, 
                    relevant content and data based on an individual’s particular job, interests and behavior."
                    web="https://apps.apple.com/us/app/korbytgo/id1237754930?platform=iphone"/>
                    <Project title="Kampus - Educational Network"
                    image="kampus.jpg" 
                    description="Kampus- your online campus! is a dream of creating the biggest online campus for students. 
                    Unlike other social networks you don't find friends or followers here, you find your classmates & the people who share similar interests or backgrounds as yours."
                    web=""/>
                    <div
                    className="flex justify-center text-center items-center p-10 bg-blue max-w-[400px] max-h-[400px] 
                    text-2xl font-playfair font-semibold"
                    >
                        WORLD CLASS MOBILE APPS
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default Projects;