import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const MySkills = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="services" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            OUR <span className="text-red">SERVICES</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7">
          Our elite teams deliver mobile apps, desktop software, world-class design work – anything internet, 
          really. We’ve spent a tremendous amount of time and money building a team of experts to save you time and money.
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="skills"
                className="z-10"
                src="assets/skills-image.png"
              />
            </div>
          ) : (
            <img alt="skills" className="z-10" src="assets/skills-image.png" />
          )}
        </div>
      </div>

      {/* SERVICES */}
      {/* ROW 1 */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* Mobile App Development */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Mobile App Development
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          We hire the best custom app development teams (choosing from more than 80 applicants for every software development position). 
          Working together in co-located teams, we constantly learn from and challenge each other. 
          Our teams stay lean and rapidly deploy customized solutions for each client.
          </p>
        </motion.div>

        {/*  Websites & Web Apps */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Websites & Web Apps
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          The modern web isn’t just for static content. It’s where people access services and connect with one another 
          daily through web software, built on the most open, flexible, and used runtime available today.
          </p>
        </motion.div>
        {/* Desktop Software */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">06</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Desktop Software
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-color4 absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          For businesses that have a lot of employees sitting at desks all day, custom built software can not only save you money, 
          but can increase productivity 10 fold. Think about it. Virtual every Fortune 500 company has custom-built desktop software. 
          There’s a reason for that, and we can do the same for you.
          </p>
        </motion.div>
      </div>

      {/* ROW 2 */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* Graphic Design */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">04</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Graphic Design
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-color1 absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          We take pride in our craft. Drawing on our deep expertise in design, our Product Design team cares for your 
          users’ experience over the entire customer journey, at every touchpoint with your company.
          </p>
        </motion.div>

        {/*  Enterprise */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">05</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Enterprise
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-color2 absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          Mobile and desktop software is really good at automating repetitive tasks and keeping things on track for businesses. 
          That’s why we have a completely separate branch of engineers dedicated to saving our enterprise clients as much money 
          as possible by using our fully customized mobile and desktop software solutions.
          </p>
        </motion.div>
        {/*  Quality Assurance */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">06</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Quality Assurance
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-color3 absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          So you built a great app? Wonderful! Did you test it? Steve Jobs was known for putting QA above almost all else. He wanted the user experience to be flawless, and this requires a whole lot of testing. 
          That’s why we have a world-class team of 20 QA engineers who do nothing but test, test, and test again.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default MySkills;
