import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
    return (
        <footer className="h-64 bg-red pt-10">
            <div className="w-5/6 mx-auto">
                <div className="md:flex justify-center md:justify-between text-center">
                    <div>
                    <SocialMediaIcons />
                    <p className="font-playfair text-md text-yellow">©2022 MAXOBYTE. All Rights Reserved.</p>
                </div>
                <div className="text-left">
                        <p>Dhaka</p>
                        <p className="mt-7">103/1 Paradise Tahmina Garden,</p>
                        <p>Rampura Banasree, Dhaka-1219</p>
                        <p>Bangladesh</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;