import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const Testimonials = () => {
    const testimonialStyles = `mx-auto relative max-w-[400px] h-[350px] flex flex-col
    justify-end p-16 mt-48 before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2`;

    return (
        <section id="testimonials" className="pt-32 pb-16">
            {/* HEADING */}
            <motion.div
            className="md:w-1/3 text-center md:text-left"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
            }}
            >
            <p className="font-playfair font-semibold text-4xl mb-5 text-red">
                TESTIMONIALS
            </p>
            <LineGradient width="mx-auto w-2/4" />
            <p className="mt-10">
            Software will change your business. Here are some of the results our clients have shared after they implement our software solutions:
            </p>
            </motion.div>

            {/* TESTiMONIALS */}
            <div className="md:flex md:justify-between gap-8">
                <motion.div
                className={`bg-blue ${testimonialStyles} before:content-person1`}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, scale:0.8 },
                    visible: { opacity: 1, scale:1 },
                }}
                >
                    <p className="font-playfair text-6xl">“</p>
                    <p className="text-center text-xl">
                    We are a small company so the $2,000 we save each month from the apps [MAXOBYTE] built for us is a game changer.
                    </p>
                </motion.div>

                <motion.div
                className={`bg-red ${testimonialStyles} before:content-person2`}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay:0.2, duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, scale:0.8 },
                    visible: { opacity: 1, scale:1 },
                }}
                >
                    <p className="font-playfair text-6xl">“</p>
                    <p className="text-center text-xl">
                    [MAXOBYTE] cut our reporting time to 3 minutes from 45 minutes for every engineer we employ.
                    </p>
                </motion.div>

                <motion.div
                className={`bg-color4 ${testimonialStyles} before:content-person3`}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay:0.4, duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, scale:0.8 },
                    visible: { opacity: 1, scale:1 },
                }}
                >
                    <p className="font-playfair text-6xl">“</p>
                    <p className="text-center text-xl">
                    we estimated our savings to be somewhere over $10,000 each month after implementing the software [MAXOBYTE] built for us.
                    </p>
                </motion.div>
            </div>
        </section>
    )
};

export default Testimonials;